.carousel_arrow{
    background: #BD8B40;
    bottom:0;
    margin-top:20;
}

.carousel_arrow:hover{
    background: #895e12;
}

@media only screen and (min-width: 960px) {
    .carousel_arrow-right{
        right: 40%;
    }
    
    .carousel_arrow-left{
        left: 40%;
    }
}